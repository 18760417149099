<template>
  <!-- <ion-header>
    <ion-title class="ion-padding"> Statistics</ion-title>
  </ion-header> -->
  <ion-content>
    <ion-grid>
      <ion-row class="ion-margin-top ion-padding-top">
        <ion-col class="user-img">
          <ve-progress
            :size="270"
            :gap="10"
            thickness="5"
            emptyThickness="1"
            :hideLegend="true"
            :data="statistics"
          />
          <img v-if="user.profileImage" :src="user.profileImage" />
          <img v-else src="../../../public/assets/img/avatar_placeholder.png" />
        </ion-col>
      </ion-row>
      <ion-row class="nome ion-text-center">
        <ion-col>
          <h3>{{ user.nome }} {{ user.cognome }}</h3>
        </ion-col>
      </ion-row>
      <ion-row class="legenda ion-padding-top">
        <ion-col>
          <ion-row v-for="statistic in statistics" :key="statistic.id">
            <ion-col size="12">
              <ion-row
                class="ion-align-items-center ion-text-center ion-justify-content-start"
              >
                <ion-col size="1">
                  <div
                    v-if="statistic.color != undefined"
                    class="legenda-indicatore"
                    :style="{ background: statistic.color }"
                  ></div>
                  <div v-else class="legenda-indicatore-grey"></div>
                </ion-col>
                <ion-col v-if="statistic.friendlyName" size="auto">
                  {{ $root.translate(statistic.friendlyName) }}:
                </ion-col>
                <ion-col v-if="showPerc" size="auto">
                  {{ ((statistic.valore / statistic.goal) * 100).toFixed(2) }}%
                </ion-col>
                <ion-col v-else size="auto">
                  <span
                    v-if="
                      whappyClientConfig.name == 'MySales' ||
                      whappyClientConfig == 'Commercial Project Management'
                    "
                  >
                    {{ statistic.valore.toFixed(0) }}/{{
                      statistic.goal.toFixed(0)
                    }}
                  </span>
                  <span v-else>
                    {{ statistic.valore.toFixed(2) }}/{{
                      statistic.goal.toFixed(2)
                    }}
                  </span>
                </ion-col>
              </ion-row>
              <ion-row
                class="ion-padding-bottom ion-margin-bottom"
                v-if="statistic.kpiHistoryLabel"
              >
                <ion-col>
                  <kpi-graph
                    :prop_dataSet="[
                      { data: statistic.kpiHistoryValue, color: primaryColor },
                      { data: statistic.kpiHistoryGoal, color: secondaryColor },
                    ]"
                    :labelsValues="statistic.kpiHistoryLabel"
                    :prop_options="{ fontColor: 'grey', colorXY: 'grey' }"
                  ></kpi-graph>
                </ion-col>
              </ion-row>
            </ion-col>
          </ion-row>
        </ion-col>
      </ion-row>
      <ion-row class="badges">
        <ion-col>
          <h4>Badges</h4>
          <div class="container-widgets">
            <div
              class="widget"
              v-for="badge in computed_badges"
              :key="badge.text"
            >
              <small-widget :item="badge"></small-widget>
            </div>
          </div>
        </ion-col>
      </ion-row>
      <ion-row class="awards">
        <ion-col>
          <h4>Awards</h4>

          <div class="container-widgets">
            <div
              class="widget"
              v-for="award in computed_awards"
              :key="award.text"
            >
              <small-widget :item="award"></small-widget>
            </div>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-content>
  <ion-footer>
    <ion-row class="ion-justify-content-center">
      <ion-col size="auto">
        <button class="px-4 py-2 button-primary" @click="closeModal">
          Close
        </button>
      </ion-col>
    </ion-row>
  </ion-footer>
</template>

<script>
import { defineComponent } from "vue"
import ApiService from "../../common/service.api"
import { hslToHex } from "../../utils/hslToRgb"
import { translate } from "../../common/helper.auth"
import KpiGraph from "../../components/KpiGraph.vue"
import SmallWidget from "../../components/SmallWidget.vue"

import {
  IonHeader,
  IonTitle,
  IonContent,
  IonFooter,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  modalController,
} from "@ionic/vue"

export default defineComponent({
  components: {
    // RadialProgressBar,
    IonHeader,
    IonTitle,
    IonContent,
    IonFooter,
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
    SmallWidget,
    KpiGraph,
  },
  name: "UserStatistics",
  props: {
    user: Object,
    idCampaign: Number,
    showPerc: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.populateStatistics()

    this.loadBadges()
    this.loadAwards()

    this.primaryColor = this.whappyClientConfig.graphLightColor_1
    this.secondaryColor = this.whappyClientConfig.graphLightColor_2
  },

  data() {
    return {
      statistics: [],
      badges: [],
      awards: [],
      primaryColor: "",
      secondaryColor: "",
    }
  },
  methods: {
    async loadBadges() {
      await ApiService.get(
        "CampaignManager/GetUserBadges/" +
          this.idCampaign +
          "/" +
          this.user.id_utente
      ).then((res) => {
        res.data.forEach((element) => {
          this.badges.push(element)
        })
        // this.badges.push(res.data);
      })
    },
    async loadAwards() {
      await ApiService.get(
        "CampaignManager/GetUserAward/" +
          this.idCampaign +
          "/" +
          this.user.id_utente
      ).then((res) => {
        res.data.forEach((element) => {
          this.awards.push(element)
        })
        // this.awards.push(res.data);
      })
    },
    closeModal() {
      return modalController.dismiss()
    },
    async populateStatistics() {
      await ApiService.get(
        `CampaignManager/GetUserKpiById/${this.idCampaign}/${this.user.id_utente}`
      ).then((res) => {
        res.data.forEach((element) => {
          element.progress = 100

          if (element.valore > element.goal) {
            element.perc_completamento = 100
          }

          this.statistics.push(element)
        })

        setTimeout(() => {
          this.statistics.forEach((element) => {
            element.progress = element.perc_completamento
          })
        }, 1500)
      })
      this.colorfy()
    },
    colorfy() {
      let colorsArray = [
        this.whappyClientConfig.primaryColor,
        this.whappyClientConfig.primaryColor_2,
        this.whappyClientConfig.secondaryColor,
        this.whappyClientConfig.secondaryColor_2,
        this.whappyClientConfig.thirdaryColor,
        this.whappyClientConfig.thirdaryColor_2,
      ]

      for (let i = 0; i < this.statistics.length; i++) {
        this.statistics[i].color = colorsArray[i]
      }

      // let hue = 200;
      // let saturation = 90;
      // let lightness = 50;

      // let range = 140 / this.statistics.length;

      // this.statistics.forEach((element) => {
      //   element.color = hslToHex(hue, saturation, lightness);
      //   // console.log(element.color);
      //   if (hue + range < 360) {
      //     hue += range;
      //   } else {
      //     hue += range - 360;
      //   }
      // });
    },
  },
  computed: {
    computed_badges() {
      return this.badges.map((x) => ({
        text: x.title["it-IT"],
        image: x.url_image,
        status: x.vinto,
      }))
    },
    computed_awards() {
      return this.awards.map((x) => ({
        text: x.title["it-IT"],
        image: x.url_image,
        status: x.vinto,
      }))
    },
  },
})
</script>

<style scoped lang="scss">
/* * {
  border: 1px solid rgb(189, 255, 189);
} */

.user-img {
  position: relative;
  display: grid;
  place-items: center;
  height: auto;
}

img {
  position: absolute;
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.legenda-indicatore {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
}

.legenda-indicatore-grey {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background: rgb(223, 223, 223);
}

.container-widgets {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 30%);
  column-gap: 1rem;
}
</style>
